/* Button.css */

.common-button {
  background-color: #06526D;
  color: #fff;
  padding: 10px 30px;
  text-align: center;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: none !important;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
}

.common-button:hover {
  color: #fff;
}
.button-text {
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  text-decoration: none !important;
  color: #06526D;
}
.button-text:hover {
  color: #06526D;
}
.outlined-defailt-btn {
  border: 1px solid #06526D;
  padding: 10px 30px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  color: #06526D;
  text-decoration: none !important;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
}
.outlined-defailt-btn:hover {
  color: #06526D;
}
.button-outline,
.common-button {
  border: 1px solid #06526D;
}
.button-outline {
  background-color: transparent;
  color: #06526D;
  padding: 10px 30px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
}

.common-button:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
  border: none;
}

.small-size {
  padding: 10px 15px;
  font-size: 11px;
}

.medium-size {
  padding: 10px 20px;
  font-size: 12px;
}

.large-size {
  padding: 10px 50px;
  font-size: 12px;
}

.extra-large-size {
  padding: 10px 80px;
  font-size: 12px;
}

.outlined-variant {
  background-color: #fff;
  border: 1px solid #06526D;
  color: #06526D;
}

.outlined-blue {
  background-color: transparent;
  border: 1px solid #1067f6;
  color: #1067f6;
}

.contained-blue {
  background-color: #1067f6;
  color: #fff;
}

.contained-variant {
  background-color: #06526D;
  color: #fff;
}

.outlined-variant:hover {
  background-color: #06526D;
  color: #fff;
}

.outlined-blue:hover {
  background-color: #1067f6;
  color: rgb(255, 255, 255);
}

.MuiFormLabel-root.MuiInputLabel-root {
  font-size: 14px;
}
.MuiInputBase-input.MuiOutlinedInput-input {
  height: 1.2em;
}

.header-logo {
  width: 80px;
  height: auto;
}

.flexContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 4px;
  max-width: 900px;
  width: 100%;
  background-color: #fff;
  border-radius: 0 0 20px 20px;
  padding: 10px;
}
.paddedFlexColumnContainer {
  padding: 1px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.flexColumnContainer {
  padding: 1px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.headerContainer {
  font-size: 15px;
  border-bottom: 1px solid #f07346;
  padding: 0.4rem 0;
  margin-bottom: 0.2rem;
  color: #f07346;
}
.selectedTextHeader {
  color: #06526D !important;
}

.headerContainer2 {
  font-size: 15px;
  border-bottom: 1px solid #017e84;
  padding: 0.4rem 0;
  margin-bottom: 0.2rem;
  color: #017e84;
}

.headerContainer3 {
  font-size: 15px;
  border-bottom: 1px solid #714b67;
  padding: 0.4rem 0;
  margin-bottom: 0.2rem;
  color: #714b67;
}

.linkContainer {
  font-size: 14px;
  padding: 2px 0;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

.linkContainer:hover {
  color: #06526D;
}
.textContainer {
  font-size: 14px;
  padding: 5px 0;
  cursor: pointer;
}

.navContainer {
  background-color: #fff;
  border-color: #dcdcdc;
  padding: 1rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.navInnerContainer {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin: auto;
  max-width: 1431px;
}

.logo img {
  /* Add your styles for the logo image here */
}

.navItemsContainer {
  align-items: center;
  display: none;
  gap: 10px;
  font-size: 14px;
}

.navItem {
  display: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.tryFree {
  color: #06526D;
}

.buttonOutline {
  /* Add your styles for the buttonOutline class here */
}

.commonButton {
  /* Add your styles for the commonButton class here */
}

.hamburgerButton {
  display: none;
  align-items: center;
  padding: 0.5rem;
  width: 2.5rem;
  height: 2.5rem;
  justify-content: center;
  font-size: 14px;
  background-color: transparent;
  color: #6b7280;
  border-radius: 0.375rem;
}

.navItemsContainerMobile {
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.navUl {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none !important;
  text-transform: none;
  list-style-type: none;
  margin-top: 1rem;
  font-weight: 500;
}

.navLi {
  position: relative;
}

.navA {
  display: flex;
  gap: 0.25rem;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 0.75rem;
  border-bottom: 1px solid #dcdcdc;
}

.navA:hover {
  background-color: #f9fafb;
}

.navA.active {
  color: #06526D;
}
.nav-link {
  cursor: pointer;
  font-weight: 500 !important;
  padding-block: 0;
  display: flex;
  color: #000;

  text-decoration: none;
  align-items: center;
  gap: 2px;
}
.nav-link:hover {
  color: #06526D;
}
.container-fluid {
  /* sm:grid sm:grid-cols-2 md:grid-cols-3 gap-4 max-w-[900px] w-full bg-[#fff] roundehidden rounded-b-[20px] px-[10px] */

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  max-width: 900px;
  width: 100%;
  background-color: #fff;
  border-radius: 0 0 20px 20px;
  padding: 10px;
  padding-top: 0;
}

.container-fluid-mobile {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  max-width: 900px;
  width: 100%;
  background-color: #fff;
  border-radius: 0 0 20px 20px;
  padding: 10px;
  padding-top: 0;
}
.mobile-drawer-main-container .MuiDrawer-paper {
  width: 95%;
  z-index: 999;
}
.divider-header-mobile {
  height: 0.5px;
  color: #bcbcbc;
}
.header-item {
  color: #000;
  text-decoration: none !important;
}
.mobile-drawer-main-container .MuiButtonBase-root.MuiListItem-root {
  align-items: flex-start !important;
}
.top-nav-main-container.MuiDrawer-root {
  top: 65px;
}
.top-nav-main-container .MuiPaper-root.MuiDrawer-paper {
  /* top: 65px !important; */
  box-shadow: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 1px solid #eee;

  /* background-color: transparent !important; */
}
.top-nav-main-container .MuiBackdrop-root {
  top: 66px !important;
}

.blogs-heading-text {
  font-size: 15px;
  border-bottom: 2px solid #06526D;
  padding: 0.5rem 0;
  margin-bottom: 0.5rem;
  color: #06526D;
}
.action-button-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media screen and (max-width: 1039px) {
}

.nav-bar-center-container {
  display: flex;
}

@media screen and (max-width: 1088px) {
  .nav-bar-center-container {
    display: none;
  }

  .utilButtons {
    display: none !important;
  }

  .hamburgerButton {
    display: block;
  }
}

.footer-widget a {
    /* color: black; */
    text-decoration: none;
}



.footer-widget li {
    list-style: none;
}

.footer-menu {
    padding-left: 0px;
}

.footer-widget {
    margin-right: 4%;
}


#follow-us-section {
    margin-top: 4%;
}

.footer-widget a:hover {
    color: #06526D;
}


.footer-menu li {
    margin-bottom: 3%;
}

.whatsapp-icon-contact-us {
    margin-right: 6%;
}

.icon-contact-us {
    margin-right: 6%;
}

@media screen and (max-width: 2000px) and (min-width: 1000px) {
    #item-container-footer {
        /* margin-top: 1%; */
    }
}

@media screen and (max-width: 1000px) {
    #global-footer {
        border-top: 1px solid #ddd;
        margin-left: 35px;
        margin-right: 35px;
    }
}

@media screen and (max-width: 990px) {
    #global-footer {
        border-top: 1px solid #ddd;
        margin-left: 30px;
        margin-right: 30px;
    }

    #item-container-footer {
        margin-right: 2%;
    }
}

@media screen and (max-width: 767px) {
    #global-footer {
        border-top: 1px solid #ddd;
        margin-left: 20px;
        margin-right: 20px;
    }

    #item-container-footer {
        margin-right: 0%;
        margin-top: 2%;
    }

    #image-logo-icon-legitt-footer {
        width: 51%;
        margin-left: 28%;
    }
}

@media screen and (max-width: 575px) {
    .footer-widget {
        margin-right: 6%;
    }
}

@media screen and (max-width: 400px) {
    #image-logo-icon-legitt-footer {
        width: 51%;
        margin-left: 23%;
    }
}
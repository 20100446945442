.success-plan-purchase-gif-container {
  animation: zoomIn 1s forwards;
}

@keyframes zoomIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.success-popup-main-content-container {
  animation: zoomIn 1s forwards;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.success-popup-content-title-span {
  font-weight: 700;
  color: #06526D;
}
.success-popup-main-right-content-description {
  font-size: 14px;
  font-weight: 400;
  color: #545454;
  margin-bottom: 10px;
}

.success-popup-main-right-content-button {
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  background-color: #616161;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}
.hide-success-plan-purchase-gif-animation {
  display: none;
}

.hide-success-plan-purchase-gif-animation-zoom-in {
  animation: zoomIn 1s forwards;
}

.hide-success-plan-purchase-gif-animation-zoom-out {
  animation: zoomOut 1s forwards;
}

@keyframes zoomOut {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}

.failed-popup-gif {
  width: 100%;
}
.success-popup-main-left-content {
  width: 50%;
}
.success-popup-main-right-content {
  width: 50%;
}
.success-popup-text {
  margin-top: -120px;
  padding-bottom: 40px;
  z-index: 1;
}
.failed-text {
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  color: #fb595e;
  margin-bottom: 10px;
}
.failed-desc {
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  color: #545454;
  margin-bottom: 10px;
}

.email-popup-upgrade.success-popup .MuiPaper-root {
  min-width: 800px !important;
  border-radius: 20px;

  overflow-y: hidden !important;
  width: 100%;
}
.success-popup-main-left-image {
  width: 100%;
}
.success-popup-main-right-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  align-items: flex-start;
}
.success-popup-main-right-content-title {
  font-size: 20px;
  text-align: left;
  font-weight: 600;
  margin-bottom: 10px;
}
.success-popup-content {
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
}

.success-plan-purchase-gif-container {
  width: 500px;
  height: 500px;
  overflow: hidden;
}

.success-plan-purchase-gif-container iframe {
  width: 100%;
  height: 100%;
  transition: transform 2s ease;
}

.page_404-page-main-container {
  padding: 40px 0;
  background: #fff;
}

.page_404-page-main-container img {
  width: 100%;
}

.page_404-page-four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  margin-top: 100px;
  background-repeat: no-repeat;
  background-position: center;
}

.page_404-page-four_zero_four_bg h1 {
  font-size: 80px;
}

.page_404-page-four_zero_four_bg h3 {
  font-size: 80px;
}

.page_404-page-contant_box_404 {
  text-align: center;
}

.back-to-dashboard-404-btn-container {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
}

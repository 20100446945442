@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"); */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* p {
  font-size: 2rem;
  color: #ffffff;
  margin: 0;
  font-weight: 700;
} */

.circle1 {
  position: fixed;
  background-color: rgba(22, 143, 255, 0.5);

  filter: blur(75px);
  width: 273px;
  height: 221px;
  z-index: 100;
  top: -155px;
  left: 40px;
  border-radius: 76% 76% 35% 65% / 27% 36% 64% 73%;
}

.circle2 {
  position: fixed;
  background-color: rgba(175, 95, 255, 0.5);
  filter: blur(75px);
  width: 190px;
  z-index: 100;
  height: 190px;
  top: -50px;
  left: -80px;
}

.circle3 {
  width: 400px;
  z-index: -1;
  position: fixed;
  background-color: rgb(175 95 255 / 17%);

  filter: blur(75px);

  height: 400px;

  filter: blur(75px);
  top: 230px;
  left: calc(50vw - 150px);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.notistack-SnackbarContainer {
  z-index: 99999999999999999999999 !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #acacac;
  transition: 0.5s;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.demo-form-input-fields.MuiTextField-root {
  background-color: #fff; /* Default background color */
  border-radius: 5px;
  border: none;
}

.demo-contact-number-text-field.PhoneInput {
  background-color: #fff; /* Default background color */
  border-radius: 5px !important;
  display: flex;
  align-items: center;
  gap: 10px;
}
.demo-contact-number-text-field .PhoneInputInput {
  background-color: #fff; /* Default background color */
  color: #000;
}

.demo-form-input-fields .MuiOutlinedInput-notchedOutline {
  border: none;
  padding: 16.5px 14px 10px;
}
.scrolled .demo-form-input-fields.MuiTextField-root,
.scrolled .demo-contact-number-text-field.PhoneInput {
  background-color: #000;
}
.scrolled .demo-form-input-fields .MuiOutlinedInput-notchedOutline {
  border: none;
  padding: 16.5px 14px 10px;
}

.demo-form-input-fields .MuiInputLabel-shrink {
  transform: translate(13px, 3px) scale(0.75);
}
.demo-form-input-fields .MuiInputLabel-root {
  font-size: 14px;
}

.demo-contact-number-text-field .PhoneInputInput::placeholder {
  font-size: 13.5px !important;
  padding-left: 5px !important; /* Adjust the size as needed */
  color: #666666 !important;
  outline: none;
}

.demo-contact-number-text-field .PhoneInputInput:focus {
  outline: none;
}
.PhoneInputCountry {
  display: flex;
  align-items: center;
  align-items: center;
}
.PhoneInputCountrySelect {
  width: 20px;
  color: #000 !important;
}
.PhoneInputCountryIcon {
  width: 30px;
}

/* CustomerSection */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 7));
  }
}

.slider {
  /* height: 170px; */
  margin: auto;
  overflow: hidden;
  position: relative;
  width: auto;
}

.slider .slide-track {
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(750px * 17);
  gap: 100px;
}

.slider .slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-ribbon {
  position: relative;
  padding-top: 30px;
}

.ribbon-container {
  position: absolute;
  top: -1px;
  right: -2px;
  width: 150px;
  height: 150px;
  overflow: hidden;
}

.ribbon-container span {
  position: absolute;
  display: block;
  width: 200px;
  padding: 5px 0;
  background-color: #000000;
  color: #fff;
  text-align: center;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  transform: rotate(45deg);
  right: -47px;
  top: 42px;
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  letter-spacing: 2px;
}

.coupon-code-popup-dialog .MuiDialog-paper {
  border-radius: 20px;
  max-width: 600px;
  width: 100%;
}

#deals-sticky-bar {
  background-color: #000080 !important;
}

#deals-toolbar {
  display: flex;
  justify-content: space-between;
  padding: 8px 8px;
}

#deals-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* margin-left: 130px; */
}

#deals-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px 0;
}

#deals-coupon-code {
  font-weight: bold !important;
}

#deals-timer-wrapper {
  /* flex-grow: 1; */
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  gap: 20px;
  margin-left: 1rem;
  margin-right: 1rem;
}

#deals-close-button {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}

#deals-heading {
  color: white !important;
  text-align: center;
  font-size: 15px;
  line-height: 1rem;
}

#deals-close-icon {
  height: 16px;
  width: 16px;
}

#deals-discount {
  font-weight: bold !important;
}

#deals-subtext {
  color: white !important;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-left: 0;
  margin-top: 4px;
}

#deals-button {
  color: #1e3a8a;
  font-weight: bold;
  background-color: white;
  padding: 0.25rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  cursor: pointer;
}

#deals-button:hover {
  text-decoration: underline;
}

@media (min-width: 640px) {
  #deals-text-wrapper {
    flex-direction: row;
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 0px;
    justify-content: center;
  }

  #deals-toolbar {
    padding: 8px 32px;
  }

  #deals-content-wrapper {
    flex-direction: row;
    justify-content: space-around;
    gap: 16px;
  }

  #deals-heading {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  #deals-subtext {
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-left: 8px;
    margin-top: 0px;
  }

  #deals-close-icon {
    height: 20px;
    width: 20px;
  }

  #deals-timer-wrapper {
    margin-top: 0;
    gap: 24px;
    margin-left: 0;
    margin-right: 0;
  }

  #deals-button {
    padding: 0.25rem;
    font-size: 1rem;
  }
}

@media (min-width: 1024px) {
  #deals-heading {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  #deals-subtext {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  #deals-text-wrapper {
    /* margin-left: 160px; */
    justify-content: center;
  }
}
